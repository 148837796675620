import React from 'react';
import './App.css';
import { CarouselImage, Details, Workouts } from './components';
import { Footer, Navbar } from './components';
import LandingPage from './pages/LandingPage';
import { Switch, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';

function App() {
  return (
    <>
      <Navbar />
      <div>
        <Container
          fluid
          style={{
            minHeight: 'calc(100vh - 1rem)',
          }}
        >
          <Switch>
            <Route exact path='/' component={LandingPage} />
            <Route exact path='/' component={CarouselImage} />
            {/* <Route path='/Workouts' component={Workouts} /> */}
            <Route path='/Details' component={Details} />

            {/* <Route exact path="/" render={() => <LandingPage />} />
        <Route exact path="/" render={() => <CarouselImage />} />
        <Route path="/details" render={() => <Details />} />
      <Route path="/Workouts" render={() => <Workouts />} /> */}
          </Switch>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default App;
