import React from 'react';
import { linkList, styles } from '../config';
import { Nav, Container, Col, Row, Card } from 'react-bootstrap';
import { Facebook, Instagram, Whatsapp, Youtube } from 'react-bootstrap-icons';

export default function Footer() {
  const { footerCard, footerWhatsApp, footerFacebook } = styles;
  const { footerYoutube, footerIframe, footerCopyrightposition } = styles;
  const { facebook, youtube, instragram, iframelink } = linkList;
  return (
    <>
      <br />
      <div>
        <Container>
          <Row>
            <Col sm={4}>
              <Card style={footerCard}>
                <Card.Body>
                  <Card.Title>
                    {' '}
                    <h3>Contact Details.</h3>
                  </Card.Title>
                  <Card.Text>
                    <Whatsapp style={footerWhatsApp} /> (+16292021342)
                    <br />
                    <Facebook
                      className='mr-2'
                      style={footerFacebook}
                      onClick={() => window.open(facebook, '_blink')}
                    />{' '}
                    Nasrat Ansari
                    <br />
                    <Youtube
                      className='mr-2'
                      style={footerYoutube}
                      onClick={() => window.open(youtube, '_blink')}
                    />{' '}
                    Nasrat Ansari
                    <br />
                    <Instagram
                      className='mr-2'
                      style={footerYoutube}
                      onClick={() => window.open(instragram, '_blink')}
                    />{' '}
                    Nasrat Ansari
                  </Card.Text>
                </Card.Body>
              </Card>{' '}
            </Col>
            <Col sm={8}>
              <div style={footerIframe}>
                <iframe
                  width={430}
                  height={200}
                  src={iframelink}
                  title='YouTube video player'
                  frameborder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                ></iframe>
              </div>
            </Col>
          </Row>

          <Row className='mt-5 text-center' style={footerCopyrightposition}>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Nav className='flex-column'>
                <Nav.Item className='text-muted ml-3'>
                  <p className='h6'>Copyright © Nasrat Ansari. 2022</p>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
