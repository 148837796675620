import React from 'react';
import { Container } from 'react-bootstrap';
import { Features, CardGroups, Fields, VideoPage } from '../components';
import { CarouselImage, Services } from '../components';

export default function LandingPage() {
  return (
    <div className=''>
      <Container>
        <VideoPage />
        <Fields />
        <Services />
        <CarouselImage />
        <Features />
      </Container>
      {/* <CardGroups /> */}
    </div>
  );
}
