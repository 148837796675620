import React, { useState } from 'react';
import { Carousel, Col, Image, Row } from 'react-bootstrap';
import IMG_9061 from '../assets/Image/IMG_9061.jpeg';
import Third from '../assets/Image/third.jpg';
import D from '../assets/Image/D.jpg';
import { styles } from '../config';
import useWindowDimensions from '../config/windows-dimention';

export default function CarouselImage() {
  const [index, setIndex] = useState(0);
  const size = useWindowDimensions();

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const { carouselImageDisplay } = styles;
  return (
    <>
      {size.width > 992 ? (
        <Row>
          <Col>
            <div style={carouselImageDisplay}>
              <Carousel
                activeIndex={index}
                onSelect={handleSelect}
                style={{ height: '60rem', width: 'auto', objectFit: '' }}
              >
                <Carousel.Item style={{ height: '60rem', objectFit: '' }}>
                  <div
                    style={{
                      height: '100rem',
                      maxHeight: '100%',
                      objectFit: '',
                    }}
                  >
                    <img
                      // width='400'
                      height='1200'
                      className=''
                      src={D}
                      alt='First slide'
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item
                  style={{ height: '60rem', width: '100%', objectFit: '' }}
                >
                  <div
                    style={{
                      height: '100%',
                      maxHeight: '100%',
                      maxWidth: '100%',
                      width: '100%',
                      objectFit: '',
                    }}
                  >
                    <img
                      width='2000'
                      height='1200'
                      className=''
                      src={IMG_9061}
                      alt='Second slide'
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item
                  style={{ height: '60rem', width: '100%', objectFit: '' }}
                >
                  <div
                    style={{
                      height: '100%',
                      maxHeight: '100%',
                      maxWidth: '100%',
                      width: '100%',
                      objectFit: '',
                    }}
                  >
                    <img
                      width='2000'
                      height='1200'
                      className=''
                      src={Third}
                      alt='Second slide'
                    />
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </Col>
        </Row>
      ) : (
        <div>
          {' '}
          <div style={carouselImageDisplay}>
            <Carousel activeIndex={index} onSelect={handleSelect}>
              <Carousel.Item>
                <Image className='d-block w-100' src={D} alt='First slide' />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100'
                  src={IMG_9061}
                  alt='Second slide'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img className='d-block w-100' src={Third} alt='Third slide' />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      )}
    </>
    /*
    <div style={carouselImageDisplay}>
    <Carousel activeIndex={index} onSelect={handleSelect}>
    <Carousel.Item>
    <Image className="d-block w-100" src={D} alt="First slide" />
    </Carousel.Item>
    <Carousel.Item>
    <img className="d-block w-100" src={IMG_9061} alt="Second slide" />
    </Carousel.Item>
    <Carousel.Item>
    <img className="d-block w-100" src={Third} alt="Third slide" />
    </Carousel.Item>
    </Carousel>
    </div>
    
    
    */
  );
}
