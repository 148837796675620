import React from 'react';
import { styles } from '../config';
import { useTranslation, Trans } from 'react-i18next';

export default function Fields() {
  const { t, i18n } = useTranslation();

  const { fieldsDivBackgroundSize, fieldsDisply, fieldTextDisplay } = styles;
  return (
    <div className='p-2' style={fieldsDivBackgroundSize}>
      <span style={fieldsDisply}>
        <h3>{t('fieldStuff')}</h3>
        <br />
      </span>
      <p style={fieldTextDisplay}>
        <h5>{t('descriptionField')}</h5>
      </p>
    </div>
  );
}
