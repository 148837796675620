import React from 'react';
import { useState } from 'react';
import { styles } from '../config';
import Icon from '../assets/blob.png';
import { useHistory } from 'react-router-dom';
import MydModalWithGrid from './Modal/Login.component';
import { Button, Container, Image, Nav, Navbar } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';

const lang = {
  en: { nativeName: 'English' },
  de: { nativeName: 'Dari' },
};

export default function Navbars() {
  const history = useHistory();
  const { navbarCursor } = styles;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { t, i18n } = useTranslation();

  return (
    <Navbar
      collapseOnSelect
      expand='lg'
      bg='light'
      variant='light'
      className=''
    >
      <Container>
        <Navbar.Brand style={navbarCursor} onClick={() => history.push('/')}>
          {' '}
          <Image
            src={Icon}
            height={40}
            al='icon'
            style={navbarCursor}
            onClick={() => history.push('/')}
          />
          <strong>{t('title')}</strong>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='me-auto'>
            {/* <Nav.Link href=''>{t('members')}</Nav.Link> */}
            {/* <Nav.Link onClick={() => history.push('/workouts')}>
              {t('members')}
            </Nav.Link> */}
          </Nav>
          <Nav>
            <div>
              {Object.keys(lang).map((lng) => {
                return (
                  <button
                    className='p-2'
                    style={{ backgroundColor: 'light', marginTop: '0.5rem' }}
                    type='submit'
                    key={lng}
                    onClick={() => i18n.changeLanguage(lng)}
                    disabled={i18n.resolvedLanguage === lng}
                  >
                    {lang[lng].nativeName}
                  </button>
                );
              })}
            </div>
            <Nav.Link
              className='p-2 m-2 mt-2'
              style={{ background: '#fc4e03' }}
              eventKey={2}
              onClick={handleShow}
            >
              <strong>{t('registration')}</strong>
            </Nav.Link>
            <MydModalWithGrid handleClose={handleClose} show={show} />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
