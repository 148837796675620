import i18next from 'i18next';

import { initReactI18next } from 'react-i18next';
import languageDedector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
i18next
  .use(initReactI18next)
  .use(languageDedector)
  .use(Backend)
  .init({
    debug: true,
    fallback: 'en',
    resources: {
      en: {
        translation: {
          title: 'ANSARI TEAM',
          members: 'Members',
          registration: 'REGISTER',
          fieldStuff:
            'TRUST • RESPECT • CREDIBILITY • CLIENT RETENTION • SUCCESS',
          descriptionField:
            'Build your health-fitness career with Ansari Team. We don’t just certify trainers…we push you to develop your fitness.',

          descriptionService:
            ' Experienced in an Oxygen gym in the capital of the Emirate (Abu Dhabi). Oxygen is one of the biggest Gym with well-known bodybuilders training.',
          descriptioonServiceMore: 'for more detail ...',
          descriptioonServicePersonal:
            ' Certified trainer in many countries in the Asian region. The certificate is approved by the school here for a personal trainer.',
          descriptionServiceBinous:
            ' Ultimate and professional gym in Dubai. This is the biggest celebrabies and well known bodybuilder in the world training place.',
          featuresAbout:
            '  I’m Mir Nasratullah Ansari. I’m the founder and lead trainer at ANSARI TEAM. I’ve been a personal trainer in U.A.E & Afghanistan since 2015. In 2020, I was hospitalized due to a Shoulder-surgery. I was underweight and very sick. Exercise quite literally saved my life and gave me back my muscle. I’m extremely passionate about what I do and my goal is always to help my clients feel the best they can in the skin they’re in, gain confidence, and enjoy a healthy life. Every individual has unique needs and goals and it’s my aim to help my clients achieve those goals while enjoying the journey. Working out shouldn’t feel miserable — I firmly believe that kids understand how to have fun with exercise and my training stylen reflects getting back to that place when fitness was fun.',
          featuresTrainer: 'Need a trainer the way you want.',
          featuresTrainerPerson:
            ' Personal Training and Expert Fitness Knowledge to Live Your Best.',
          featureExpert: 'Expert Guidance And Support.',
          featureExpertStudies:
            '  Studies show that personal training doubles your chances of overall exercise success and body fat % reduction compared to working out alone. The best part? Do it all in the comfort and privacy of your own home.',
          ModalTitle: 'Contact!',
          ModalBody:
            'we are possibly working on our software to make online payments and team work! Catch me on WhatsApp ATM!',
          Modalfooter: 'Call on WhatsApp!',
          MONDAY: 'MONDAY',
          TUESDAY: 'TUESDAY',
          WEDNESDAY: 'WEDNESDAY',
          THURSDAY: 'THURSDAY',
          FRIDAY: 'FRIDAY',
          WEEKEND: 'WEEKEND',
        },
      },
      de: {
        translation: {
          title: 'تیم انصاری',
          members: ' عضو و فیمتها',
          registration: 'ثبت نام',
          fieldStuff: 'اعتماد • احترام • اعتبار • حفظ مشتری • موفقیت',
          descriptionField:
            'حرفه تناسب اندام خود را با تیم انصاری بسازید. ما فقط به مربیان گواهی نمی‌دهیم، بلکه شما را تشویق می‌کنیم تا تناسب اندام خود را توسعه دهید.',
          descriptionService:
            ' تجربه در یک سالن ورزشی اکسیژن در پایتخت امارات (ابوظبی).Oxygen یکی از بزرگترین سالن های بدنسازی با بدنسازان مشهور استآموزش',
          descriptioonServiceMore: 'برای جزئیات بیشتر',
          descriptioonServicePersonal:
            'مربی معتبر در بسیاری از کشورهای منطقه آسیا.این گواهی توسط مدرسه اینجا برای یک مربی شخصی تایید شده است.',
          descriptionServiceBinous:
            'باشگاه ورزشی عالی و حرفه ای در دبی. این بزرگترین سلبریتی و بدنساز شناخته شده در محل تمرین جهان است.',
          featuresAbout:
            'من میر نصرت الله انصاری هستم. من بنیانگذار و مربی اصلی تیم انصاری هستم. من از سال 2015 در امارات متحده عربی و افغانستان مربی شخصی هستم. در سال 2020 به دلیل جراحی شانه در بیمارستان بستری شدم. وزنم کم بود و خیلی مریض بودم. ورزش به معنای واقعی کلمه زندگی من را نجات داد و عضله ام را به من بازگرداند. من در مورد کاری که انجام می‌دهم بسیار مشتاق هستم و هدفم همیشه این است که به مشتریانم کمک کنم بهترین احساس را در پوست خود داشته باشند، اعتماد به نفس پیدا کنند و از یک زندگی سالم لذت ببرند. هر فردی نیازها و اهداف منحصر به فردی دارد و هدف من کمک به مشتریانم برای رسیدن به آن اهداف در عین لذت بردن از سفر است. ورزش کردن نباید ناراحت کننده باشد - من کاملاً معتقدم که بچه ها می دانند چگونه با ورزش سرگرم شوند و سبک تمرینی من نشان دهنده بازگشت به آن مکان است که تناسب اندام سرگرم کننده بود.',
          featuresTrainer: 'به مربی آنطور که می خواهید نیاز دارید.',
          featuresTrainerPerson:
            'آموزش شخصی و دانش تخصصی تناسب اندام برای زندگی شما بهترین.',
          featureExpert: 'راهنمایی و پشتیبانی تخصصی',
          featureExpertStudies:
            'مطالعات نشان می دهد که آموزش شخصی شانس شما را دو برابر می کند موفقیت کلی ورزش و کاهش درصد چربی بدن در مقایسه باکار کردن به تنهایی بهترین قسمت؟ همه این کارها را با خیال راحت انجام دهید وحریم خصوصی خانه خودتان',
          ModalTitle: '!تماس',
          ModalBody:
            'ما احتمالاً روی نرم افزار خود کار می کنیم تا پرداخت های آنلاین و کار تیمی انجام دهیم من را در دستگاه واتس اپ بگیرید',
          Modalfooter: '!با واتساپ تماس بگیرید',
          MONDAY: 'دو شنبه',
          TUESDAY: 'سه شنبه',
          WEDNESDAY: 'چهار شنبه',
          THURSDAY: 'پنچ شنبه',
          FRIDAY: 'جمعه',
          WEEKEND: 'تعطیلات آخر هفته',
        },
      },
    },
  });
