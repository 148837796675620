import React from 'react';
import D from '../assets/Image/Oxygen.jpg';
import personalTrainer from '../assets/Image/Personaltrainer.jpg';
import Binous from '../assets/Image/Binous.jpg';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

export default function Services() {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  return (
    <div className='marketing p-5'>
      <div className='row'>
        <div className='col-lg-4'>
          <img
            className='bd-placeholder-img rounded-circle'
            width='140'
            height='140'
            aria-label='Placeholder: 140x140'
            preserveAspectRatio='xMidYMid slice'
            focusable='false'
            src={D}
          />

          <h2>Oxygen</h2>
          <p>
            {t('descriptionService')}
            <br />
            {t('descriptioonServiceMore')}
          </p>
          <p>
            <span
              className='btn btn-secondary'
              onClick={() => history.push('/details')}
            >
              View details
            </span>
          </p>
        </div>
        <div className='col-lg-4'>
          <img
            className='bd-placeholder-img rounded-circle'
            width='140'
            height='140'
            aria-label='Placeholder: 140x140'
            preserveAspectRatio='xMidYMid slice'
            focusable='false'
            src={personalTrainer}
          />

          <h2>Personal trainer</h2>
          <p>
            {t('descriptioonServicePersonal')}
            <br /> {t('descriptioonServiceMore')}
          </p>
          <p>
            <span
              className='btn btn-secondary'
              onClick={() => history.push('/details')}
            >
              View details
            </span>
          </p>
        </div>
        <div className='col-lg-4'>
          <img
            className='bd-placeholder-img rounded-circle'
            width='140'
            height='140'
            aria-label='Placeholder: 140x140'
            preserveAspectRatio='xMidYMid slice'
            focusable='false'
            src={Binous}
          />
          <h2>Binous Gym</h2>
          <p>
            {t('descriptionServiceBinous')}
            <br />
            {t('descriptioonServiceMore')}
          </p>
          <p>
            <span
              className='btn btn-secondary'
              onClick={() => history.push('/details')}
            >
              View details
            </span>
          </p>
        </div>
      </div>
      <hr />
    </div>
  );
}
