import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import Trainer from '../assets/Image/IMG_9197.jpeg';
import gym2 from '../assets/Image/gym2.jpg';
import gym3 from '../assets/Image/IMG_0668.PNG';
import { styles } from '../config';
import { useTranslation, Trans } from 'react-i18next';

export default function Features() {
  const { t, i18n } = useTranslation();

  const {
    featuresBorder,
    featuresParagraphPosition,
    featuresMarginTop,
    featureImageMargin,
  } = styles;
  return (
    <Container>
      <Row>
        <Col sm={8}>
          <Card className='text-center w-100' style={featuresBorder}>
            <Card.Header style={featuresBorder}>
              About me! <span className='text-muted'>Daddo.</span>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <p className='lead m-0 ml-5' style={featuresParagraphPosition}>
                  {t('featuresAbout')}
                  {'   '}
                  <br />
                  <br />
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={4}>
          {' '}
          <Button
            variant='none'
            style={{
              cursor: 'default',
            }}
          >
            <Card.Img
              style={{
                border: '2px solid #5ebed4',
                padding: '10px',
                borderRadius: '50px 20px',
                boxShadow: '5px 10px #888888',
              }}
              src={gym3}
              alt='pic 1'
              // style={featurImageposition}
            ></Card.Img>
          </Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col sm={4}>
          {' '}
          <Card.Img
            src={Trainer}
            alt='pic 1'
            style={featuresMarginTop}
          ></Card.Img>
        </Col>
        <Col sm={8}>
          {' '}
          <Card className='text-center' style={featuresBorder}>
            <Card.Header style={(featuresBorder, featuresMarginTop)}>
              TRAIN FOR LIVE
            </Card.Header>
            <Card.Body style={featureImageMargin}>
              <Card.Title>
                <span className='text-muted'>{t('featuresTrainer')}</span>
              </Card.Title>
              <Card.Text>
                <p className='lead m-0 ml-5'>{t('featuresTrainerPerson')}</p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <br />
      <hr />
      <Row>
        <Col sm={8}>
          <Card
            className='text-center'
            style={(featuresBorder, featuresMarginTop)}
          >
            <Card.Header style={featuresBorder}> And lastly</Card.Header>
            <Card.Body>
              <Card.Title>
                <span className='text-muted'>{t('featureExpert')}</span>
              </Card.Title>
              <Card.Text>
                <p className='lead m-0 ml-5'>{t('featureExpertStudies')}</p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={4}>
          <Card.Img src={gym2} alt='pic 1' style={featuresMarginTop}></Card.Img>
        </Col>
      </Row>
      <br />
      <hr />
    </Container>
  );
}
