import React, { useState } from 'react';
import { Container, CardGroup, Card, Carousel } from 'react-bootstrap';
import Oxygen from '../../assets/Image/Oxygen.jpg';
import Binouss from '../../assets/Image/Binous.jpg';
import TransformCert from '../../assets/Image/transformCert.jpg';
import FitnessCert from '../../assets/Image/FitnessInst.jpg';
import byfCert from '../../assets/Image/bfyCet.jpg';

export default function Details() {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div
      className=''
      style={{
        display: 'block',
        width: '100%',
        height: '50rem',
        // padding: '30px',
      }}
    >
      <Carousel
        className=''
        activeIndex={index}
        onSelect={handleSelect}
        style={{ height: '', position: '' }}
      >
        <Carousel.Item>
          <img
            style={{ height: '100rem', objectFit: '' }}
            variant=''
            src={Binouss}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ height: '100rem', objectFit: '' }}
            variant=''
            src={Oxygen}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ height: '100rem', objectFit: '' }}
            variant=''
            src={TransformCert}
          />
        </Carousel.Item>
        {/* <Carousel.Item>
          <img className='' variant='' src={FitnessCert}></img>
        </Carousel.Item>
        <Carousel.Item>
          <img className='' variant='' src={byfCert}></img>
        </Carousel.Item> */}
      </Carousel>
    </div>
  );
}
