import React, { useRef } from 'react';
import { Form, Image, InputGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';
import * as yup from 'yup';
import { Formik } from 'formik';

export default function MydModalWithGrid({ handleClose, show }) {
  const { t, i18n } = useTranslation();
  const form = useRef();

  const schema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    zip: yup.string().required(),
    number: yup.string().required(),
    terms: yup.bool().required().oneOf([true], 'Terms must be accepted'),
  });
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_qh4o1dz',
        'template_42l31of',
        form.current,
        'VEpF-5Un2rZ8wziIX'
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log('message sent');
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <Modal
      show={show}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
    >
      <Modal.Header closeButton onClick={handleClose}>
        <Modal.Title id='contained-modal-title-vcenter'>
          {t('title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='show-grid'>
        <Modal.Body>
          <Formik
            validationSchema={schema}
            onSubmit={console.log}
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              city: '',
              state: '',
              zip: '',
              number: '',
              terms: false,
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row className='mb-3'>
                  <Form.Group as={Col} md='4' controlId='validationFormik01'>
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      type='text'
                      name='firstName'
                      placeholder='firstName'
                      value={values.firstName}
                      onChange={handleChange}
                      isValid={touched.firstName && !errors.firstName}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md='4' controlId='validationFormik02'>
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      type='text'
                      name='lastName'
                      placeholder='lastName'
                      value={values.lastName}
                      onChange={handleChange}
                      isValid={touched.lastName && !errors.lastName}
                    />

                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md='4'
                    controlId='validationFormikUsername'
                  >
                    <Form.Label>Username</Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Text id='inputGroupPrepend'>
                        @
                      </InputGroup.Text>
                      <Form.Control
                        type='email'
                        placeholder='Email'
                        aria-describedby='inputGroupPrepend'
                        name='email'
                        value={values.email}
                        onChange={handleChange}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.email}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Row>
                <Row className='mb-3'>
                  <Form.Group as={Col} md='6' controlId='validationFormik03'>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='City'
                      name='city'
                      value={values.city}
                      onChange={handleChange}
                      isInvalid={!!errors.city}
                    />

                    <Form.Control.Feedback type='invalid'>
                      {errors.city}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md='3' controlId='validationFormik04'>
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='State'
                      name='state'
                      value={values.state}
                      onChange={handleChange}
                      isInvalid={!!errors.state}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.state}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md='3' controlId='validationFormik05'>
                    <Form.Label>Zip</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Zip'
                      name='zip'
                      value={values.zip}
                      onChange={handleChange}
                      isInvalid={!!errors.zip}
                    />

                    <Form.Control.Feedback type='invalid'>
                      {errors.zip}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md='5' controlId='validationFormik06'>
                    <Form.Label>Mobile</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Mobile number'
                      name='number'
                      value={values.number}
                      onChange={handleChange}
                      isInvalid={!!errors.number}
                    />

                    <Form.Control.Feedback type='invalid'>
                      {errors.number}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md='1'
                    controlId='validationFormik06'
                  ></Form.Group>
                  <Form.Group
                    style={{ marginTop: '2.4rem' }}
                    className='mb-3'
                    md='6'
                    as={Col}
                  >
                    <Form.Check
                      required
                      name='terms'
                      label='Agree to terms and conditions'
                      onChange={handleChange}
                      isInvalid={!!errors.terms}
                      feedback={errors.terms}
                      feedbackType='invalid'
                      id='validationFormik0'
                    />
                  </Form.Group>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        {/* <Container>
          <Row>
            <Col xs={12} md={6}>
              <h2>Telephone{'   '}#</h2>
            </Col>
            <Col xs={6} md={6}>
              <h2>+16292021342</h2>
            </Col>
          </Row>

          <Row>
            <Col xs={6} md={4}></Col>
            <Col xs={6} md={4}></Col>
          </Row>
        </Container> */}
      </Modal.Body>
      <Modal.Footer>
        <Button type='submit'>Submit form</Button>
        <Button
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
