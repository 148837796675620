import React from 'react';
import { Container } from 'react-bootstrap';
import { linkList } from '../config/linkList';
function VideoPage() {
  const { videoLink } = linkList;
  return (
    <Container
      style={{
        position: 'relative',
        // width: '100%',
        overflow: 'hidden',
        paddingTop: ' 56.25%' /* 16:9 Aspect Ratio */,
      }}
    >
      <iframe
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          bottom: '0',
          right: '0',
          width: '100%',
          height: '100%',
          border: 'none',
        }}
        src='//drive.google.com/file/d/1jVo4gN_7-NU2WfbngcLk7Rqpub-JP8n9/preview?start=1'
        // height='auto'
        title='Ansari Team'
        allow='autoplay'
        allowfullscreen
        loop
      ></iframe>
    </Container>
  );
}

export default VideoPage;
