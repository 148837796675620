export const styles = {
  navbarCursor: {
    cursor: 'pointer',
  },

  carouselImageDisplay: {
    width: '100%',
    height: 'auto',
    background: 'grey',
    display: 'felx',
    flexWrap: 'wrap',
    backgroundImage:
      'url(https://images.unsplash.com/photo-1612090295951-656aaeac99d9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80)',
  },
  featuresBorder: {
    border: 'none',
  },
  featuresMarginTop: {
    marginTop: '5rem',
  },
  featurImageposition: { marginTop: '0rem' },
  featuresParagraphPosition: {
    textAlign: 'justify',
  },
  featureImageMargin: {
    marginTop: '2rem',
  },
  fieldsDivBackgroundSize: {
    background: '#000814',
    width: '100%',
    height: '15rem',
  },
  fieldsDisply: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffff',
    padding: '1rem',
  },
  fieldTextDisplay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffff',
    padding: '1rem',
  },

  footerCard: {
    color: 'gray',
    border: 'none',
  },
  footerWhatsApp: {
    display: 'inline-block',
    color: 'green',
  },
  footerFacebook: {
    cursor: 'pointer',
    display: 'inline-block',
    color: 'blue',
  },
  footerYoutube: { cursor: 'pointer', display: 'inline-block', color: 'red' },
  footerIframe: {
    display: 'flex',
    marginRight: '2rem',
  },
  footerCopyrightposition: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  navBarBrandStyle: {
    cursor: 'pointer',
    paddingLeft: '5rem',
    paddingBottom: '2.5rem',
    position: 'relative',
  },
  navLinkBasicStyle: { width: '60px', backgroundColor: 'dark' },
  navDropDownStyle: { width: '60px', marginLeft: '20px' },
  navDropDownEveStyle: { width: '50px', backgroundColor: 'dark' },
  desktopCardStyle: {
    width: '24rem',
    border: 'none',
  },
  desktopCardTitleStyle: {
    textTransform: 'uppercase',
    lineHeight: '2rem',
  },
  desktopRowContainerStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    margin: '0rem',
  },
  desktopCardImgOverlayStyle: {
    height: '7rem',
    marginLeft: '0.05rem',
    color: '#fff',
    opacity: '0.8',
    marginTop: '8rem',
    borderBottomLeftRadius: '15px',
    borderBottomRightRadius: '15px',
    backgroundColor: 'black',
    position: 'relative',
    zIndex: '2',
  },
  fullWidthHeightStyle: { height: '100%', width: '100%' },
  desktopFadeStyleOff: {
    width: '8rem',
    height: '3rem',
    backgroundColor: '#949494',
    border: 'none',
    fontSize: '1.3rem',
    outline: 'none',
    position: 'absolute',
  },
  desktopRowDivStyle: {
    backgroundColor: 'black',
    borderRadius: '15px',
    height: '100%',
  },
  desktopCardImgStyle: {
    cursor: 'pointer',
    objectFit: 'cover',
    margin: '.05rem',
    height: '15rem',
    borderRadius: '12px',
    zIndex: '1',
    position: 'relative',
  },
  desktopContainerDivStyle: {
    display: 'flex',
    justifyContent: 'center',
    justifyItem: 'center',
    padding: '1.5rem',
  },
  desktopFadeStyleOn: {
    width: '8rem',
    height: '3rem',
    backgroundColor: '#BAB6B6',
    border: 'none',
    fontSize: '1.3rem',
    outline: 'none',
    position: 'absolute',
  },
};
